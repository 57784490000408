import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, TextField } from "@mui/material";
import { generateFirestoreAutoId } from '../Contexts/utilFunctions';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useDispatch,useSelector } from 'react-redux';
import { addClient } from '../Redux/slices/clientSlice';

function CreateNewUserModal({ open, onClose, clients, setClients, headerText, type,currentClient,collaborator_id,collaborator}) {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const clientsStore = useSelector(state => state.clients);
  const [clientData, setClientData] = useState({
    client_id: "",
    name: "",
    organizationName: "",
    email: "",
    phone: "",
    website: "",
    proposals: {},
  });
  const baseurl = process.env.REACT_APP_API_URL;

  /*useEffect(() => {
    setClientData({ client_id: generateFirestoreAutoId(), organizationName: currentClient?.organization_name||'', });
  }, [setClients]);*/

  useEffect(() => {
    setClientData(prev => ({
      ...prev,
      client_id: generateFirestoreAutoId(),
      organizationName: currentClient?.organization_name || '',
    }));
  }, [currentClient]);


  const handleChange = (e) => {
    setClientData({ ...clientData, [e.target.name]: e.target.value });
    if(e.target.name==='organizationName'){
      setError(null)
    }
  }; 
  
  const handleSave=async ()=>{
    try {
      const response = await fetch(`${baseurl}/edit-collaborator`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: type ,
          user_id: localStorage.getItem("user_id"),
          collaborator_id: collaborator_id,
          collaborator_data: {
            contact_person: clientData.name||collaborator.contact_person,
           // organization_name: clientData.organizationName || currentClient?.organization_name,
            email: clientData.email||collaborator.email,
            phone: clientData.phone|| collaborator.phone,
            website: clientData.website || collaborator.website
          },
        }),
      });
      if (response.ok) {
        const result = await response.json();


        // Dispatch updated client data to Redux
        if(!type){
          dispatch(addClient({
            client_id: clientData.client_id,  // Client's unique ID
            contact_person: clientData.name,
            organization_name: clientData.organizationName,
            email: clientData.email,
            phone: clientData.phone,
            website: clientData.website,
            proposals: clientData.proposals,
          }));
        }


        // Reset form fields
        /*setClientData({
          client_id: generateFirestoreAutoId(),
          name: "",
          organizationName: "",
          email: "",
          phone: "",
          website: ""

        }); */

        if (type) {
          setClients(prevCollaborators => [
            ...prevCollaborators,
            {
              contact_person: clientData.name,
              email: clientData.email,
              phone: clientData.phone,
            }
          ]);
        }

        // Close the form modal after successful creation
        onClose();
        window.location.reload()
    }

   }
   catch(e){
        console.log("Error")
   }
  }


  const handleCreateClient = async () => {
    if (type==='User_Collaborator'){
      if (!clientData.name || !clientData.email) {
        alert("Please fill in all required fields.");
        return;
      }
    }else{
      if (!clientData.name ||!clientData.email || (!type && !clientData.organizationName)) {
        alert("Please fill in all required fields.");
        return;
      }
    }

    try {
      const response = await fetch(`${baseurl}/add-collaborator`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: type || "Client_Organization",
          user_id: localStorage.getItem("user_id"),
          collaborator_id: clientData.client_id,
          collaborator_data: {
            contact_person: clientData.name,
            organization_name: clientData.organizationName || currentClient?.organization_name,
            email: clientData.email,
            phone: clientData.phone,
            website: clientData.website,
            proposals: clientData.proposals,
          },
        }),
      });

      if (response.ok) {
        const result = await response.json();


        // Dispatch updated client data to Redux
        if(!type){
          dispatch(addClient({
            client_id: clientData.client_id,  // Client's unique ID
            contact_person: clientData.name,
            organization_name: clientData.organizationName,
            email: clientData.email,
            phone: clientData.phone,
            website: clientData.website,
            proposals: clientData.proposals || {},
          }));
        }


        if (type) {
          setClients(prevCollaborators => [
            ...prevCollaborators,
            {
              contact_person: clientData.name,
              email: clientData.email,
              phone: clientData.phone,
            }
          ]);
        }

        setClientData({
          client_id: generateFirestoreAutoId(),
          name: "",
          organizationName: "",
          email: "",
          phone: "",
          website: "",
          proposals:{}

        });

        // Close the form modal after successful creation
        onClose(); 
       // window.location.reload()          
      } else {
        
        console.error('Failed to add client organization:', response.statusText);
      }
    } catch (error) {
      console.error('Error occurred while adding client organization:', error);
    }
  };

  // Fetch client details again (if needed)
  // const userId = localStorage.getItem("user_id");
  // setClients(prevClients => {
  //   // Convert the current clients object to an array
  //   const clientsArray = Object.values(prevClients);

  //   // Add the new client to the array
  //   const updatedArray = [...clientsArray, {
  //     type: "Client_Organization",
  //     user_id: localStorage.getItem("user_id"),
  //     collaborator_id: clientData.client_id,
  //     collaborator_data: {
  //       contact_person: clientData.name,
  //       organization_name: clientData.organizationName,
  //       email: clientData.email,
  //       phone: clientData.phone,
  //       website: clientData.website,
  //     },
  //   }];

  //   // Convert the updated array back to an object with indexed keys
  //   const updatedObject = updatedArray.reduce((acc, client, index) => {
  //     acc[index] = client;
  //     return acc;
  //   }, {});

  //   return [updatedObject]; // Ensure to return the updated object
  // });

  // Reset the form fields after successful creation

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: "#11002e",
    border: '2px solid #5406D2',
    boxShadow: 24,
    p: 4,
  };

  const customStyle = {
    bgcolor: "black",
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'gray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'blue',
      },
    },
    input: { color: 'white' },
    label: { color: 'white' },
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        {error&&(<p className='text-center font-bold text-white text-[20px]'>{error}</p>)}
        <Typography
          sx={{ display: "flex", justifyContent: "center" }}
          id="modal-title"
          variant="h6"
          component="h2"
          color="white"
        >
         {collaborator?"Edit Client":"New Client Details"}
        </Typography>
        <TextField
          id="name"
          label="Name"
          name="name"
          value={clientData.name}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          required
          sx={{ mt: 2, ...customStyle }}
        />
        {type === 'Client_Collaborator' ? (
          <Box sx={{ minWidth: 120 }}>
            <FormControl
              fullWidth
              sx={{ backgroundColor: "black", ...customStyle, marginTop: "15px" }}
              required
            >
              {currentClient?(
                <TextField
                  id="organization-name"
                  label="Organization Name"
                  name="organizationName"
                  value={currentClient.organization_name}
                  onChange={handleChange} // You can remove this if there's no need for changes since it's read-only
                  variant="outlined"
                  fullWidth
                  required
                  InputProps={{
                    readOnly: true, // Makes the text field uneditable
                  }}
                  sx={{ mt: 2, ...customStyle }}
                />
              ):(
                 <InputLabel id="demo-simple-select-label">Error Please Select Current Client</InputLabel>        
              )}

            </FormControl>
          </Box>
        ) : null}

        {headerText?null:(
          <TextField
            id="organization-name"
            label="Organization Name"
            name="organizationName"
            value={clientData.organizationName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            required
            sx={{ mt: 2, ...customStyle }}
          />
        )}

        <TextField
          id="email"
          label="Email Address"
          name="email"
          value={clientData.email}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          required
          sx={{ mt: 2, ...customStyle }}
        />
        <TextField
          id="phone"
          label="Phone Number"
          name="phone"
          value={clientData.phone}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          sx={{ mt: 2, ...customStyle }}
        />
        <TextField
          id="website"
          label="Website"
          name="website"
          value={clientData.website}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          sx={{ mt: 2, ...customStyle }}
        />
        <div className="file-upload-btns">
          <Button
            onClick={() => {
              if(type==='Client_Collaborator'&& collaborator_id){
                if (!clientData.name ||!clientData.email || (!type && !clientData.organizationName)) {
                  alert("Please fill in all required fields.");
                  return;
                }
                handleSave()
              }
             else if(type==='User_Collaborator'){
                handleCreateClient()}
                else{
                  
                if (clientData && clientData.organizationName) {
                  
                  // Check if clientsStore is not empty
                  if (clientsStore && Object.keys(clientsStore).length > 0) {
                    // Get the second key from clientsStore
                    const secondKey = Object.keys(clientsStore)[1];

                    // Extract clients from the second key
                    const clients = clientsStore[secondKey]
                      ? Object.entries(clientsStore[secondKey]).filter(([client_id,client])=>client.is_delete===false).map(([client_id, client]) => ({
                        ...client,
                        client_id,  // Add client_id to the client object
                      }))
                      : [];
                      
                    // Check if the organization exists in the extracted clients
                    setTimeout(()=>{
                      const organizationExists = clients.some(client =>
                        client.organization_name.trim().toLowerCase() === clientData.organizationName.trim().toLowerCase() &&
                        client.is_delete === false
                    );
                     
                      // Provide feedback based on the check
                      if (organizationExists && !currentClient ) {
  
                        setError('Organization Already Exists!');
                      } else {
                       handleCreateClient()
                      }
                    },300)
                    
                  }
                }

              }
            }}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            {collaborator ? 'Edit Client' : 'Create'}
          </Button>
          <Button
            onClick={()=>{
              onClose()
              // Reset form fields
         setClientData({
          client_id: generateFirestoreAutoId(),
          name: "",
          organizationName: "",
          email: "",
          phone: "",
          website: ""

        });
              setError(null)
            }}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default CreateNewUserModal;