import React, { useEffect, useState } from "react";
import { auth } from "../../utils/firebase";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import InfoIcon from '@mui/icons-material/Info';
import "./clientdashboard.css";
import NavBar from "../../Components/NavBar";

const clients = [
    'Client Name 1',
    'Client Name 2',
    'Client Name 3',
    'Client Name 4',
];

const proposals = [
    { name: 'Proposal Name 1', due: '08/05/2024', assigned: 'Assignee 1', status: 'due' },
    { name: 'Proposal Name 2', due: '12/01/2024', assigned: 'Assignee 2', status: 'due' },
    { name: 'Proposal Name 1', due: '01/01/2023', assigned: 'Assignee 3', status: 'awarded' },
    { name: 'Proposal Name 2', due: '08/01/2024', assigned: 'Assignee 2', status: 'draft' },
    { name: 'Proposal Name 1', due: '08/11/2023', assigned: 'Assignee 1', status: 'done' },
    { name: 'Proposal Name 2', due: '09/01/2023', assigned: 'Assignee 2', status: 'in-progress' },
];

const ClientProposalDashboard = () => {
    const navigate = useNavigate();
    const [totalRows, setTotalRows] = useState(0);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (!user) {
                navigate("/login");
            } else {
                const uid = user.uid;
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    // useEffect(() => {
    //     const updateTotalRows = () => {
    //         const proposalList = document.querySelector('.proposal-list');
    //         if (proposalList) {
    //             const rowHeight = 20; // Set the height of each row, adjust as necessary
    //             const totalHeight = proposalList.clientHeight;
    //             setTotalRows(Math.ceil(totalHeight / rowHeight));
    //         }
    //     };

    //     updateTotalRows();
    //     window.addEventListener('resize', updateTotalRows);

    //     return () => {
    //         window.removeEventListener('resize', updateTotalRows);
    //     };
    // }, []);



    function capitalizeFirstLetter(text) {
        if (text === null || text === undefined || text === '') {
            return text; // Handle empty or null strings
        }
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    const getStatusBorderColor = (status) => {
        switch (status) {
            case 'due':
                return '#FF9900';
            case 'awarded':
                return '#22F400';
            case 'draft':
                return '#FFF384';
            case 'in-progress':
                return '#FFFFFF';
            case 'done':
                return '#4886FF';
            default:
                return '#dadde9';
        }
    };

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ status }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 700,
            backgroundColor: 'black',
            border: `2px solid ${getStatusBorderColor(status)}`,
        },
    }));

    const renderEmptyDivs = (count) => {
        return Array.from({ length: count }, (_, index) => (
            <div key={`empty-${index}`} className={`client-proposal-row ${index % 2 === 0 ? 'bg-light' : '#11002e'}`}>
                <div className="w-1/6"></div>
                <div className="border-r-4 border-[#5406D2] m-[0.67rem] h-[110%]"></div>
            </div>
        ));
    };

    const emptyDivsCount = 15 - clients.length;

    const sortedProposals= [...proposals].sort((a,b)=>{
        const dateA = new Date(a.due);
        const dateB = new Date(b.due);
        return dateA-dateB;
      })
     
    
    

    return (
        <div>
            <NavBar />

            <div className="bg-[#250062]">
                <div className="h-[64.5rem] w-[3.2rem] bg-[#250062]">

                </div>
                <div className="client-dash top-12">
                    <div className="flex mb-1 mt-1">
                        <div className="w-1/6 items-center justify-between flex flex-row">
                            <Typography
                                sx={{ display: "flex", justifyContent: "left" }}
                                id="modal-title"
                                color="white"
                                className="pl-3 pt-8 pb-8 text-xs"
                            >
                                Client List
                            </Typography>
                            {/* <Typography
                                id="modal-title"
                                color="white"
                                className="pl-3 pt-8 pb-8 text-xs"
                            >
                                Filter List
                            </Typography> */}
                        </div>
                        <div className="ml-2">
                            <button className="button1 ml-2">Show Only Due Proposals</button>
                            {/* <input type="text" className="search-bar text-white" placeholder="Search" /> */}
                        </div>
                    </div>
                    <div className="proposal-list ml-1">
                        <div className="bg-[#784DBD] border-2 border-[#DBC5FF] w-1/6 h-[45px] pt-2 mb-1">View All Clients</div>
                        {clients.map((client, index) => (
                            <div key={index} className={`client-proposal-row ${index % 2 === 0 ? 'bg-light' : '#11002e'}`}>
                                <div className="client-name w-1/6">{client}</div>
                                <div className="border-r-4 border-[#5406D2] mr-2 h-[110%]"></div>
                                {sortedProposals.map((proposal, idx) => (
                                    <div key={idx} className={`proposal-card ${proposal.status}`}>
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="proposal-card-header p-1">{proposal.name.length > 15 ? `${proposal.name.slice(0, 15)}...` : proposal.name}</div>
                                            <CustomWidthTooltip
                                                placement="top"
                                                TransitionComponent={Fade}
                                                className={`tooltip ${proposal.status}`}
                                                status={proposal.status}
                                                title={
                                                    <Box component="div" sx={{ fontSize: 12 }}>
                                                        <div className="w-[200px]">
                                                            {proposal.name}<br />
                                                            Due: {proposal.due}<br />
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <div className="mr-2">{capitalizeFirstLetter(proposal.status)}</div>
                                                                <div>
                                                                    Assigned: {proposal.assigned.length > 10 ? `${proposal.assigned.slice(0, 10)}...` : proposal.assigned}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Box>
                                                }
                                            >
                                                <InfoIcon className={`infoIcon ${proposal.status}`} sx={{ mr: 2 }} fontSize="small" />
                                            </CustomWidthTooltip>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ))}
                        {renderEmptyDivs(emptyDivsCount > 0 ? emptyDivsCount : 0)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientProposalDashboard;
