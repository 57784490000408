import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepPurple } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import { AiOutlineTeam } from "react-icons/ai";
import useMediaQuery from '@mui/material/useMediaQuery';
import CreateNewUserModal from './CreateNewUserModal';
import { useSelector } from 'react-redux';

const Collaborators = ({ headerText, type, currentClient }) => {
    const [open, setOpen] = useState(false);
    const [selectedCollaborator, setSelectedCollaborator] = useState(null);
    const userSlice = useSelector(state => state.user);
    const userId = localStorage.getItem('user_id') || userSlice?.id;
    const [collaborators, setCollaborators] = useState([]);
    const [collaborator_id, setCollaborator_Id] = useState("");
    const [loading, setLoading] = useState(true);
    const[organization,setOrganization]=useState("")
    const [error, setError] = useState(null);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const visibleCollaborators = isSmallScreen ? 0 : 3;
    const baseurl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        
        const fetchCollaborators = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${baseurl}/get-collaborators`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        type: type,
                        user_id: userId,
                        organization_name:organization
                    }),
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch collaborators");
                }
                const result = await response.json();
                if (currentClient) {
                    const collaborators = result?.collaborator_data || [];
                    const filteredCollaborators = collaborators.filter(collaborator => collaborator.organization_name === currentClient.organization_name) || [];
                    setCollaborators(filteredCollaborators);
                } else {
                    setCollaborators(result?.collaborator_data || []);
                }
            } catch (err) {
                console.log("Error Getting Collaborators", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCollaborators();
    }, [userId, type, currentClient]);

    const getInitials = (name) => {
        if (!name) return '';
        const names = name.split(' ');
        return names.length > 1 ? `${names[0][0].toUpperCase()}${names[1][0].toUpperCase()}` : names[0][0].toUpperCase();
    };

    const handleCollaboratorClick = async (collaborator) => {
        if (!collaborator) return;  // Ensure no action when there's no collaborator

        try {
            const response = await fetch(`${baseurl}/get-collaborator`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    type: type,
                    user_id: userId,
                    contact_person: collaborator.contact_person,
                    organization_name:collaborator.organization_name
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to fetch collaborator details");
            }

            const result = await response.json();
            setCollaborator_Id(result.collaborator_id);
            setOrganization(result.data.organization_name)
            setSelectedCollaborator(collaborator);
            setOpen(true);
        } catch (err) {
            console.log("Error Getting Collaborator", err);
            setError(err.message);
        }
    };

    return (
        <>
            {open && <CreateNewUserModal open={open} onClose={() => setOpen(false)} headerText={headerText || null} type={type || null} setClients={setCollaborators} currentClient={currentClient} collaborator_id={collaborator_id} collaborator={selectedCollaborator} />}
            <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1}>
                    {collaborators.length - visibleCollaborators > 0 && (
                        <Avatar sx={{ width: 30, height: 30 }} onClick={() => { setSelectedCollaborator(null); setCollaborator_Id(""); setOpen(true); }}>
                            <AddIcon fontSize="small" sx={{ width: 10, height: 10 }} />
                            <p className='text-sm'> {collaborators.length - visibleCollaborators} </p>
                        </Avatar>
                    )}
                    {collaborators.slice(0, visibleCollaborators).map((collaborator, index) => (
                        <Avatar key={index} sx={{ bgcolor: deepPurple[500], width: 30, height: 30 }} onClick={() => handleCollaboratorClick(collaborator)}>
                            <p className='text-sm'>{getInitials(collaborator?.contact_person) || "N/A"}</p>
                        </Avatar>
                    ))}
                    <div className='relative top-1' onClick={() => { setSelectedCollaborator(null); setCollaborator_Id(""); setOpen(true); }} aria-label="Open team modal">
                        <AiOutlineTeam size={20} />
                    </div>
                </Stack>
            </Stack>
        </>
    );
};

export default Collaborators; 
