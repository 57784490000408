import React, { useEffect, useState } from "react";
import WrapperModalPlan from "../../Components/WrapperPlan";
import ProposalCard from "../../Components/ProposalCard";
import { auth, } from "../../utils/firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, Typography, Skeleton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CreateNewUserModal from "../../Components/CreateNewUserModal";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import "./dashboard.css";
import { useSelector } from "react-redux";
import NavBar from "../../Components/NavBar";
import Collaborators from "../../Components/Collaborators";
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteIcon from "@mui/icons-material/Delete";
//imports needed for refetching data 
import getClientDetails from "../../utils/getClientDetails";
import { useDispatch } from "react-redux";
function Main() {
  const isSmallScreen = useMediaQuery('(max-width:640px)');
  const isMediumScreen = useMediaQuery('(max-width:1024px)');
  const isLargeScreen = useMediaQuery('(min-width:1025px) and (max-width:1440px)');
  const isXLargeScreen = useMediaQuery('(min-width:1441px)');
  const clientsStore = useSelector(state => state.clients);
  const [data, setData] = useState([]);
  const [clients, setClients] = useState({});
  const [clientNames, setClientNames] = useState([]);
  const location = useLocation();
  const [currentClient, setCurrentClient] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [uid,setUid]= useState('');
  const navigate = useNavigate();
  const baseurl = process.env.REACT_APP_API_URL;

  //refetching data on page reload
const dispatch = useDispatch()
useEffect(() => {
  getClientDetails(dispatch)
}, [dispatch]) // Add dispatch to dependency array
  //MUI styles 
  const AddClientButton = styled(Button)({
    height: "45px",
    color: "white",
    width: "97%",
    fontSize: "18px",
    backgroundColor: "#380093",
    border: "solid 3px #5406D2",
    margin: "5px 0",
  });

  // const ViewAllClientButton = styled(Button)({
  //   backgroundColor: "#784DBD",
  //   border: "2px solid #DBC5FF",
  //   width: "97%",
  //   height: "45px",
  //   color: "white",
  // });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        navigate("/login");
      } else {
        const uid = user.uid;
        setUid(uid);
        await readData(uid, "");
      }
    });
  
    return () => unsubscribe();
  }, [navigate, clientsStore]);
  
  
  useEffect(() => {
    if (location.state?.currentClient) {
      setCurrentClient(location.state.currentClient)
    }
    if (location.state?.organization_name) {
      setClientNames(location.state.organization_name)
    }
  }, [location.state])

  useEffect(() => {
    const lastClient = localStorage.getItem('LastClient');
    const clientIds = Object.keys(clientsStore);

    // If there is at least one client
    if (clientIds.length > 1) {
      // If the user has only one client
      if (clientIds.length === 1) {
        setCurrentClient({ ...clientsStore[clientIds[0]][Object.keys(clientsStore[clientIds[0]])[0]], client_id: Object.keys(clientsStore[clientIds[0]])[0] });
      }
      // If the user has more than one client
      else {
        // Check if the lastClient exists in the clientsStore
        const foundLastClient = clientIds.some(
          clientId => clientsStore[clientId][lastClient]
        );

        if (lastClient && foundLastClient) {
          setCurrentClient({ ...clientsStore[clientIds[1]][lastClient], client_id: lastClient });
        } else {
          // Fallback to setting the first available client if no last client is found
          setCurrentClient({ ...clientsStore[clientIds[1]][Object.keys(clientsStore[clientIds[1]])[0]], client_id: Object.keys(clientsStore[clientIds[1]])[0] });
        }
      }
    }
  }, [clientsStore]);

  async function readData(uid, client_id) {
    try {
      const response = await fetch(`${baseurl}/get-user-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: uid }),
      });

      const sortedData = await response.json();
      const clientsData = [];
  
      if (sortedData.sub_collections && Object.keys(sortedData.sub_collections).length > 0) {
        const clientID = Object.keys(sortedData.sub_collections)[0];
  
         if ((currentClient && currentClient === clientID) || clientID === client_id) {
          for (const [clientId, clientArray] of Object.entries(sortedData.sub_collections)) {
            clientArray.filter(client => !client.is_delete).forEach(client => {
              clientsData.push({ id: clientId, ...client });
            });
          }
        }
      }
  
      setData(clientsData.length > 0 ? clientsData : []);  // Set data or clear if empty
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }



  const handleNew = () => {
    if (currentClient === "") {
      alert("Select/click a client to add the new proposal.");
    } else {
      navigate("/new-proposal-dashboard", { state: { currentClient } });
    }
  };

  const handleClick = (formDetails) => {
      // Navigate with URL parameters
      navigate(`/grant-assistant/${currentClient.client_id}/${formDetails.id}`, { 
        state: { 
          formDetails, 
          currentClient, 
          from: "proposal-dashboard" 
        } 
      });
    };
  
    const handleDelete = async (client) => {
      try {
        const response = await fetch(`${baseurl}/delete-organization`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ uid, client_id: client.client_id, organization_name: client.organization_name }),
        });
    
        if (!response.ok) {
          throw new Error("Failed to delete client");
        }
    
        // Ensure the Redux store is updated first
        dispatch({ type: 'REMOVE_CLIENT', payload: client.client_id });
    
        // Update local state based on the updated Redux store
        const updatedClientsStore = { ...clientsStore };
        delete updatedClientsStore[client.client_id];
    
        setClients(updatedClientsStore); 
    
        const remainingClients = Object.values(updatedClientsStore).flatMap(Object.values);
    
        if (remainingClients.length > 0) {
          setCurrentClient(remainingClients[0]);
          localStorage.setItem('LastClient', remainingClients[0].client_id);
        } else {
          setCurrentClient(null);
          localStorage.removeItem('LastClient');
        }
    
        
       await getClientDetails(dispatch);
      } catch (error) {
        console.error("Error deleting client:", error);
      }
    };

  return (
    <div className="overflow-hidden max-h-screen">
      <NavBar />

      <div className="dash-2 top-12">
        <div className="w-1/5 bg-[#11002e] border-r-4 border-[#5406D2] overflow-auto flex">
          <div className="w-1/6 bg-[#250062] flex">
            {/* <Fab size="small"></Fab> */}
          </div>
          <div className="w-full flex-col space-y-12">
            <Typography
              sx={{ display: "flex", justifyContent: "center", position: "relative", top: "1.5rem" }}
              id="modal-title"
              color="white"
            >
              Client List
            </Typography>

            <AddClientButton
              type="button"
              className=" mt-32   "
              onClick={() => setOpenModal(true)}
            >
              <AddCircleOutlineIcon />
              <div className="text-[16px] pl-1 font-thin lowercase rounded-md"> <span className="uppercase">N</span>ew <span className="uppercase">C</span>lient</div>

            </AddClientButton>

            <CreateNewUserModal
              open={openModal}
              onClose={() => setOpenModal(false)}
              clients={clients}
              setClients={setClients}
            />
            {/* <Link to="/all-client-dashboard">
              <ViewAllClientButton
                type="button"
                onClick={handleOpen}
              >
                <div>View All Clients</div>
              </ViewAllClientButton>
            </Link> */}
            <div className="client-list relative bottom-[1.77rem]">
              {!clientsStore || Object.keys(clientsStore).length <= 1 ? (
                // Display Skeleton components while clientsStore is not loaded
                <div className="flex flex-col items-center justify-center">
                  <Skeleton sx={{ bgcolor: '#5406D2', p: 2.3, my: 0.25, border: '784DBD' }} variant="rounded" height={14} width="97%" />
                  <Skeleton sx={{ bgcolor: '#5406D2', p: 2.3, my: 0.25, border: '784DBD' }} variant="rounded" height={14} width="97%" />
                  <Skeleton sx={{ bgcolor: '#5406D2', p: 2.3, my: 0.25, border: '784DBD' }} variant="rounded" height={14} width="97%" />
                </div>
              ) : (
                (() => {
                  const secondKey = Object.keys(clientsStore)[1];
                  const clients = clientsStore[secondKey]
                    ? Object.entries(clientsStore[secondKey]).filter(([_,client])=>!client.is_delete).map(([client_id, client]) => ({
                      ...client,
                      client_id, // Add client_id to the client object
                    }))
                    : [];

                  return clients.length > 0 ? (
                    clients.filter(client=>!client.is_delete).map(client => (
                
                      <div
                        key={client.client_id}
                        className={`rounded-md h-[34px] w-[98.5%] text-white text-[15px] my-1 flex justify-between items-center px-2.5 border-3 ${currentClient?.organization_name && currentClient.client_id === client.client_id
                          ? 'bg-[#784DBD] border-[#DBC5FF] border-[3px]'
                          : 'bg-[#380093] border-[#5406D2] border-[3px]'
                          }`}
                        onClick={() => {
                          

                          setCurrentClient({ ...client, client_id: client.client_id });
                          localStorage.setItem('LastClient', client.client_id);
                        }}
                      >
                        <p>
                          {isSmallScreen
                            ? client.organization_name.length > 10
                              ? `${client.organization_name.slice(0, 5)}...`
                              : client.organization_name
                            : isMediumScreen
                              ? client.organization_name.length > 12
                                ? `${client.organization_name.slice(0, 10)}...`
                                : client.organization_name
                              : isLargeScreen
                                ? client.organization_name.length > 25
                                  ? `${client.organization_name.slice(0, 15)}...`
                                  : client.organization_name
                                : isXLargeScreen
                                  ? client.organization_name.length > 40
                                    ? `${client.organization_name.slice(0, 20)}...`
                                    : client.organization_name
                                  : client.organization_name}
                        </p>
                        <div>
                          <DeleteIcon
                          fontSize="small"
                          onClick={()=>{
                            handleDelete(client)
                          }}
                          
                          ></DeleteIcon>
                          </div>
                      </div>
                    ))
                  ) : (
                    // Fallback if clients array is empty
                    <div className="flex flex-col items-center justify-center text-white">
                      {clientNames.map((clientName, index) => (
                        <div
                          key={index}
                          className="rounded-md h-[34px] w-[98.5%] text-white text-[15px] my-0.5 flex items-center px-2.5 border-3 bg-[#380093] border-[#5406D2] border-[3px]"
                          onClick={() => {
                            setCurrentClient({ organization_name: clientName, client_id: clientName });
                            localStorage.setItem('LastClient', clientName);
                          }}
                        >
                          <p>{clientName}</p>
                        </div>
                      ))}
                    </div>
                  );
                })()
              )}
            </div>
          </div>
        </div>
        <div className="w-4/5 ml-3 bg-[#11002e] ">
          <div className=" mb-4 mt-3  lg:flex md:flex items-center justify-between ">
            <div className="flex justify-start">
              <button className=" rounded-md mx-2 text-sm lg:w-[260px]  md:w-[200px]  w-[150px]  relative bottom-0 md:bottom-0 sm:bottom-0 sm:top-[.20] h-[45px] border-[3px] border-[#A85101] bg-[#813E00] text-white ">Show Due Proposals</button>
              {/* <input
                type="text"
                className="  lg:w-[330px]  md:w-[200px]  w-[150px] lg:p-2 md:p-2 pt-2 pb-2  relative bottom-0 text-white border-[3px] border-[#5406D2] bg-[#250062]  placeholder:text-gray-300 focus:outline-none focus:ring-2 focus:ring-[#5406D2]"
                placeholder="Search"
              /> */}
            </div>
            <div className=" mt-2 flex justify-end lg:bottom-0 md:bottom-0 top-4 absolute right-6">
              {/* user collab */}
              <Collaborators headerText={"User Collaborator"} type={"User_Collaborator"} />
            </div>
          </div>

          <div className="inner-section mb-4 flex justify-between">
            <p className="font-thin">Your proposals are in order by due date { }</p>
            <div className="font-thin relative  bottom-1">
              {/* client collab */}
              {
                currentClient && currentClient.organization_name ? (
                  <Collaborators headerText={"Client Collaborator"} type={"Client_Collaborator"} currentClient={currentClient} />
                ) : null
              }

            </div>
          </div>
          <div className="flex w-full mt-1">
            <div className="dash p-1 d-flex items-center gap-3 bg-red-400">
              {/* loads the add proposal when there is no proposals var in the currentClientobj  */}
              {!(currentClient && currentClient.proposals && currentClient.proposals.length > 0 && !clientsStore || Object.keys(clientsStore).length <= 1) && (
                <WrapperModalPlan>
                  <div className="dashboard-box flex flex-col items-center justify-center" onClick={() => handleNew()}>
                    <AddCircleOutlineIcon />
                    <div className="category2 text-lg">New Proposal Dashboard</div>
                    <div className="category4 text-lg">Select a client to add a proposal to</div>
                    <div className="category4 text-lg">Access templates or create a new proposal</div>
                  </div>
                </WrapperModalPlan>
              )}
              {/* loads skeleton when there is proposals var in the currentClientobj but proposals is 0 because it is loaing */}
              {(currentClient && currentClient.hasOwnProperty('proposals') && currentClient.proposals.length === 0 && !clientsStore || Object.keys(clientsStore).length <= 1) ? (
                <div className="flex flex-row gap-3 w-full">
                  <Skeleton sx={{ p: 4, bgcolor: '#3b265c' }} variant="rounded" height={160} width={260} />
                  <Skeleton sx={{ p: 4, bgcolor: '#3b265c' }} variant="rounded" height={160} width={260} />
                  <Skeleton sx={{ p: 4, bgcolor: '#3b265c' }} variant="rounded" height={160} width={260} />
                </div>
              ) : currentClient.hasOwnProperty('proposals') && currentClient.proposals.length > 0 ? (
                <>
                  {currentClient?.proposals && Array.isArray(currentClient.proposals) &&
                    [...currentClient.proposals].sort((a,b)=>new Date(a.Due_Date)-new Date(b.Due_Date))
                    .map((item, index) => {
                      if ((item.is_deleted === undefined || !item.is_deleted)) {
                        
                        return (
                          <ProposalCard
                            key={index}
                            item={item}
                            index={index}
                            handleClick={() => handleClick(item)}
                            clientID={currentClient.client_id}
                          />
                        );
                      }
                      return null; // If `is_deleted`, render nothing for this proposal.
                    })}
                </>
              ) : (
                <h1><KeyboardDoubleArrowLeftIcon /> Click on New Proposal Dashboard to create your first Proposal!</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;