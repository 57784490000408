import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../../utils/firebase";
import { query, doc, getDocs, collection } from "firebase/firestore";
import "./login.css";
import { useDispatch } from 'react-redux';
import getClientDetails from "../../utils/getClientDetails";
function Login() {
  const baseurl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const [currentError, setCurrentError] = useState();
  //redux  store
  /*the fetch function to get the client details is only once and then set the client details to the redux store which 
is then called in the proposal dashboard and new proposal dashboard with the use of the use selector hook
*/
  const fetchClientNames = async (uid) => {
    try {
      const response = await fetch(`${baseurl}/get-client-names`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: uid }),
      });

      const result = await response.json();

      if (result.status === 'success') {
        const organizationNames = result.organization_names || [];
        return organizationNames;
      } else {
        console.error('Error fetching client names:', result.message);
      }
    } catch (error) {
      console.error('Error fetching client names:', error);
    }
  };




  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          getClientDetails(dispatch); // Move it here
          navigate("/proposal-dashboard");
        }
      });
      return () => unsubscribe();
  }, [navigate, dispatch]);


  async function checkOldUser(uid) {
    const userDocRef = doc(db, "Users", uid);
    const clientOrgCollectionRef = collection(userDocRef, "Client_Organization");
    const q = query(clientOrgCollectionRef);
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      return true;
    } else {
      return false;
    }
  }

const onLogIn = async (e) => {
    e.preventDefault();
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        const uid = user.uid;
        localStorage.setItem("user_id", uid);
        
        // Pass dispatch instead of uid
        await getClientDetails(dispatch);
        
        const organization_name = await fetchClientNames(uid);
        const oldUser = await checkOldUser(uid);
        
        if (oldUser) {
            navigate("/proposal-dashboard", { 
                state: { organization_name: organization_name } 
            });
        } else {
            navigate("/form");
        }
    } catch (error) {
        console.log(error);
        setCurrentError(error);
    }
};

  return (
    <div className="login-page">
      <div className="wrapper">
        <div className="title">Login Form</div>
        <div>
          {currentError && <p className="text-center text-red-700 my-2">{currentError.code.replace('auth/', '')}</p>}
        </div>
        <form onSubmit={onLogIn}>
          <div className="field">
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="email">Email Address</label>
          </div>
          <div className="field">
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="password">Password</label>
          </div>
          <div className="content">
            <div className="checkbox">
              <input type="checkbox" id="remember-me" />
              <label htmlFor="remember-me">Remember me</label>
            </div>
            <div className="pass-link">
              <Link to="/reset-password">Forgot password?</Link>
            </div>
          </div>
          <div className="field">
            <input type="submit" id="submit Login" value="Login" />
          </div>
          <div className="signup-link">
            Not a member?{" "}
            <Link to="/signup">Sign Up</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
