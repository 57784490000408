import { setClients } from "../Redux/slices/clientSlice";
import { setUser } from "../Redux/slices/userSlice";
export default async function getClientDetails(dispatch) {
    const baseurl = process.env.REACT_APP_API_URL;
    const uid = localStorage.getItem("user_id");
    try {
      const response = await fetch(`${baseurl}/get-client-details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: uid }),
      });

      const response2 = await fetch(`${baseurl}/get-user-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: uid }),
      });


      const result = await response.json();
      // fetch proposal details from firebase Client Proposal Forms collection
      const proposalResult = await response2.json()

      if (result.status === "success" && proposalResult) {

        //map or filter 
        function mapProposalFormsToClient(clientObj, proposalObj) {
          const newObj = JSON.parse(JSON.stringify(clientObj))
          for (const clientId in proposalObj) {
            if (newObj.hasOwnProperty(clientId)) {
              if (!newObj[clientId].hasOwnProperty('proposals')) {
                newObj[clientId].proposals = []
              }
              newObj[clientId].proposals = proposalObj[clientId]
            } else {
              console.warn(`Client ID ${clientId} does note exist in clientObj`)
            }
          }
          return newObj
        }

        const combinedResult = mapProposalFormsToClient(result.data, proposalResult['sub_collections'])
        // append proposals to new field called proposals
        dispatch(setClients(combinedResult))

      } else {
        console.log("errorrrr:", proposalResult)
        console.error("Error fetching client details:", result.message);
      }
    } catch (error) {
      console.error("Error making API call:", error);
    }

    //user slice
    const userDataResponse = await fetch(`${baseurl}/get-user-personal-info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uid: uid }),
    });
    const userPersonalInfo = await userDataResponse.json();

    dispatch(setUser(Object.values(userPersonalInfo.data)[0]))

  }